<template>
    <div>
      <h2>Lambda</h2>
      apiUrl
      <v-btn @click="sendPostRequest(apiUrl_LambdaApi)"></v-btn>

      <div>
        {{dataFromLambda}}
      </div>
    </div>
</template>

<script>
import { ref } from "vue";

// import firebase from "firebase/compat/app";
import "firebase/compat/database";

  export default {
    name: 'LambdaService',
    setup() {
      // ################
      // ソート用モジュール
      // ################
      // const sortModule = require('lodash')

      // ################
      // DBへのデータ格納
      // ################
      // const name = ref("")
      // const content = ref("")
      // const point = ref(50)

      // ##################
      // AWS-Lambda
      // ##################
      const apiUrl_LambdaApi = "https://sdlkv7ydm2.execute-api.ap-northeast-1.amazonaws.com/LambdaApi/"
      const dataFromLambda = ref("")

      const sendGetRequest = (apiUrl) => {
        fetch(apiUrl).then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok ' + response.statusText);
          }
          return response.json()
        })
        .then(data => {
          console.log(data); // パースされたデータを使用する
          dataFromLambda.value = data
        })
        .catch(error => {
          console.error('There has been a problem with your fetch operation:', error);
          dataFromLambda.value = error
        });
      }
      
      const sendPostRequest = (apiUrl) => {
        fetch(apiUrl, {
          // POSTリクエストの指定
          method: 'POST',
          // JSON形式のデータを送信するためのヘッダー
          headers: {
            'Content-Type': 'application/json',
          },
          // ボディに送信するデータをJSON形式で指定
          body: JSON.stringify({
            "key1": "value1",
            "key2": "value2",
            "key3": "value3",
            "message": "this is a original message from vue"
          })
        })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok ' + response.statusText);
          }
          // JSONレスポンスをパースする
          return response.json();
        })
        .then(data => {
          // パースされたデータを使用する
          console.log(data);
          dataFromLambda.value = data
        })
        .catch(error => {
          console.error('There has been a problem with your fetch operation:', error);
          dataFromLambda.value = error
        });
      }

      // ###############
      // ライフサイクル #
      // ###############
      // onMounted (() => {
      //   getArticles()
      // })

      return {
        // AWSとの通信
        apiUrl_LambdaApi,
        dataFromLambda,
        sendPostRequest,
        sendGetRequest,
      }
    }
  }
</script>
  