<template>
  <div>
    <h2>ぼやき</h2>
    <fieldset class="botaki-fieldset__overwite">
      <legend>
        <h3>タイトル</h3>
        <h4>2024/mm/dd</h4>
      </legend>
      <div>
          内容
      </div>
    </fieldset>
  </div>
</template>

<script>
  // 標準モジュール
  import { useRouter } from 'vue-router';
  
  // 外部モジュール
  
  // 独自モジュール
  
  export default {
    name: 'MurmurList',
    setup() {
      const router = useRouter()
  
      const openPage = (target) => {
        router.push(`/${target}`)
      }
      
      return {
        openPage,
      };
    },
  }
</script>
<style>
.botaki-fieldset__overwite{
  width: 100vw !important;
}
</style>
  