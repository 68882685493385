<template>
  <header>
    <div class="container">
      <div class="header__title">
        <!-- タイトル -->
        <a
          class="header__title_link"
          @click="openPage('')"
        >なりじのかくれが</a>
        <div class="header__content">
          作成したアプリを紹介しています。
        </div>
      </div>
      <!-- メニュー -->
      <v-menu>
        <template v-slot:activator="{ props }">
          <v-btn v-bind="props" class="menu-btn__overwrite">
            <v-icon class="menu-btn-icon__overwrite" color="purple-darken-2" icon="mdi mdi-apps" size="x-large"></v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in menuItems"
            :key="index"
            :value="index"
          >
            <v-list-item-title @click="openPage(item.nextPage)">{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </header>
</template> 

<script>
// 標準モジュール
import { useRouter } from 'vue-router';

// 外部モジュール

// 独自モジュール

export default {
  name: 'HeaderMenu',
  setup() {
  // ##########
  // 画面遷移 #
  // ##########
  const router = useRouter()
  const openPage = (target) => {
    router.push(`/${target}`)
  }

  // ##########
  // メニュー #
  // ##########
  const menuItems = [
    // { title: 'プロフィール', nextPage: 'ProfileTop' },
    { title: 'アプリ紹介', nextPage: 'GameTop' },
    // { title: 'ぼやき', nextPage: 'MurmurTop' },
    // { title: 'FireBaseのテスト', nextPage: 'FireBaseTop' },
    { title: 'お買い物メモ', nextPage: 'KaimonoTop' },
    // { title: 'AWS(Lambda)のテスト', nextPage: 'AwsTop' },
  ]

    
  return {
    openPage,
    // メニュー
    menuItems,
  }
}
}
</script>
<style scoped>
.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}
</style>