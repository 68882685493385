<template>
  <v-container>
    <!-- タイトル -->
    <v-col cols="12" md="4" class="v-col__for-profile__overwite">
      <v-card>
        <v-card-title class="headline">自己紹介</v-card-title>
        <v-card-text>
          <v-avatar class="mb-3" size="120" tile>
            <img src="https://via.placeholder.com/120" alt="Profile Image">
          </v-avatar>
          <p>好きな漫画: 鋼の錬金術師</p>
          <p>好きなゲーム: メイプルストーリー</p>
          <p>好きな食べ物: 肉・ジャンキーな奴</p>
        </v-card-text>
      </v-card>
    </v-col>

    <!-- 経歴 -->
    <v-col cols="12" md="8" class="v-col__for-career__overwite">
      <v-card>
        <v-card-title class="headline">経歴(ろくでもないけど、運だけは良かった人生の記録)</v-card-title>
        <v-card-text>
          <v-timeline  side="end">
            <v-timeline-item
              v-for="(item, index) in timelineItems"
              :key="index"
              :dot-color="item.color"
              fill-dot
            >
              <template v-slot:opposite>
                <div>{{ item.date }}</div>
              </template>
              <template v-slot:default>
                <v-card class="elevation-2" outlined>
                  <v-card-title class="v-card-title__for-career__overwite">{{ item.title }}</v-card-title>
                  <v-card-subtitle>{{ item.subtitle }}</v-card-subtitle>
                  <v-card-text>{{ item.content }}</v-card-text>
                </v-card>
              </template>
            </v-timeline-item>
          </v-timeline>
        </v-card-text>
      </v-card>
    </v-col>
  </v-container>
</template>

<script>
// 標準モジュール
import { useRouter } from 'vue-router';

// 外部モジュール

// 独自モジュール

export default {
  name: 'GameTop',
  setup() {
    const router = useRouter()

    const openPage = (target) => {
      router.push(`/${target}`)
    }

    // #####
    // 経歴 #
    // #####
    const timelineItems = [
      {
        date: '2015年3月',
        title: '高校卒業',
        subtitle: '',
        content: '某高校を卒業し、浪人生活に突入',
        color: 'green',
        icon: 'mdi-star'
      },
      {
        date: '2015年4月',
        title: '浪人生活',
        subtitle: '',
        content: '駿台での浪人生活',
        color: 'grey',
        icon: 'mdi-rocket-launch'
      },
      {
        date: '2016年',
        title: '暗黒期到来',
        subtitle: '',
        content: '浪人に失敗し、絶望。ネトゲで現実逃避。',
        color: 'black',
        icon: 'mdi-rocket-launch'
      },
      {
        date: '2017年4月',
        title: 'F欄大へ入学',
        subtitle: '',
        content: '城西大学でモラトリアムを延長',
        color: 'black',
        icon: 'mdi-calendar-today'
      },
      {
        date: '2017年',
        title: 'プログラミングを始める',
        subtitle: '諦めを繰り返す',
        content: 'Javaの環境構築に失敗し諦める。 → Pythonの環境構築に成功する。 → pipのインストールができず、あきらめる。',
        color: 'black',
        icon: 'mdi-calendar-today'
      },
      {
        date: '2018年4月',
        title: 'プログラミングのバイトを探し出す。',
        subtitle: 'なかなか見つからない。',
        content: 'JavaBronze取ったり、標準入力のじゃんけんゲームとか作った気がする。覚えてないけど',
        color: 'black',
        icon: 'mdi-calendar-today'
      },
      {
        date: '2019年らへん',
        title: 'IT企業でのアルバイトを開始',
        subtitle: '人生の転換期',
        content: 'この会社には本当に感謝しないといけない',
        color: 'red',
        icon: 'mdi-calendar-today'
      },
      {
        date: '2021年3月',
        title: '社会人生活スタート',
        subtitle: '1社目',
        content: 'ひたすら勉強',
        color: 'primary',
        icon: 'mdi-calendar-today'
      },
      {
        date: '2022年10月',
        title: '英会話を始める',
        subtitle: '',
        content: '初回面談での評価は、10段階中「2」',
        color: 'yellow',
        icon: 'mdi-calendar-today'
      },
      {
        date: '2024年2月',
        title: 'TOEIC-初受験',
        subtitle: 'スコア585点',
        content: 'ここまで1年と4か月',
        color: 'yellow',
        icon: 'mdi-calendar-today'
      },
      {
        date: '2024年4月',
        title: 'TOEIC-2回目の受験',
        subtitle: 'スコア755点',
        content: 'ここまで1年半',
        color: 'yellow',
        icon: 'mdi-calendar-today'
      },
      {
        date: '2024年7月',
        title: '初めての転職',
        subtitle: '2社目',
        content: '',
        color: 'primary',
        icon: 'mdi-calendar-today'
      },
    ]
    
    return {
      openPage,
      // 経歴
      timelineItems,
    };
  },
}
</script>
<style>
.v-card-title__for-career__overwite {
  white-space: unset !important
}
.v-col__for-career__overwite {
  max-width: 100vw !important
}
.v-col__for-profile__overwite {
  max-width: 50vw !important
}
</style>
<style scoped>
  .v-card-title {
    font-weight: bold;
  }

  .v-avatar img {
    border-radius: 50%;
  }
</style>