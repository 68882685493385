<template>
  <HeaderMenu />
  <ContentsArea />
  <FooterMenu />
</template>

<script>
import HeaderMenu from './components/menu/HeaderMenu.vue'
import ContentsArea from './ContentsArea.vue'
import FooterMenu from './components/menu/FooterMenu.vue'

export default {
  name: 'App',
  components: {
    HeaderMenu,
    ContentsArea,
    FooterMenu,
  }
}

</script>

<style>
    body {
        width: 100%;
        height: 100%;
        margin: unset !important;
        padding: unset !important;
    }
    header {
        background-color: #333;
        color: #fff;
        text-align: center;
        padding: 20px 0px;
    }
    footer {
        background-color: #333;
        color: #fff;
        text-align: center;
        padding: 20px 0px;
    }
    .section__contents {
        padding: 20px 20px;
        text-align: -webkit-center;
    }
    .header__title {
        font-size: 36px;
        margin-bottom: 10px;
        flex: 1;
        text-align: center;
    }
    .header__title_link {
        text-decoration:none;
        color: white;
    }
    .header__content {
        font-size: 18px;
    }
    .cta-button {
        display: inline-block;
        background-color: #007bff;
        color: #fff;
        padding: 3px 10px;
        text-decoration: none;
        border-radius: 5px;
        transition: background-color 0.3s;
    }
    .cta-button:hover {
        background-color: #0056b3;
    }
    .notice__title {
        text-align-last: center;
    }
    .notice {
        font-size: 16px;
        background-color: #f8d7da;
        color: #721c24;
        padding: 10px;
        border-radius: 5px;
        width: fit-content;
        text-align-last: left;
    }
    fieldset {
        width: fit-content;
    }
    .app__title {
        font-size: x-large;
        font-weight: bold;
    }
    .app__download {
        font-size: larger;
        font-weight: bold;
    }
    .app__content{
        text-align-last: left;
    }
    .app__scene{
        font-size: larger;
        font-weight: bold;
        display: flex;
        max-width: 80vw;
        overflow-x: scroll;
        gap: 8px;
    }
    .contact{
        font-size: large;
    }
    .contact__column{
        font-size: small;
    }
    .rights{
        font-size: x-small;
        margin-top: 12px;
    }
    .link__privacy_policy{
        color: white;
    }
    .hideColor{
        color: #fff;
    }
    
    .link { 
        text-decoration: underline;
    }
    .menu-btn__overwrite{
        padding: unset !important;
        min-width: fit-content !important;
        height: fit-content !important;
    }
    .menu-btn-icon__overwrite{
        padding: unset !important;
        width: 30px !important;
        min-height: 30px !important;
    }
</style>