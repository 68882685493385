import { createApp } from "vue";
import App from "./App.vue";
import router from "./plugins/router";

// スタイル
import vuetify from "./plugins/vuetify";
import '@mdi/font/css/materialdesignicons.css'
import "./assets/main.css";

// FireBase導入-パートB
// firebase
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import "firebase/compat/storage";

// FireBase導入-パートC
// ■初期化
var firebaseConfig = {
  apiKey: "AIzaSyARcrDiw5dvJ1ma8mSwXvqp-5nL4qjVNbw",
  authDomain: "firesample-fa717.firebaseapp.com",
  databaseURL: "https://firesample-fa717-default-rtdb.firebaseio.com",
  projectId: "firesample-fa717",
  storageBucket: "firesample-fa717.appspot.com",
  messagingSenderId: "513126628902",
  appId: "1:513126628902:web:9b21c998188bb2874ef0e7",
  measurementId: "G-PYRZLM3SN4"
};
firebase.initializeApp(firebaseConfig);
window.firebase = firebase;

const app = createApp(App)
app.use(router).use(vuetify)
app.mount("#app")
