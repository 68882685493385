<template>
  <div class="notice">
    <h2 class="notice__title">
      ★テスター募集のお知らせ★
    </h2>
    <p>
      Android用製品版リリースに向けて、Androidユーザーのテスト協力者を募集しています。
    </p>
    <h4>■テストの参加方法</h4>
    <p>
      1. GooglePlayに登録しているGmailアドレスを nariji.app@gmail.com に送信してください。<br>
      2. クローズドテストに招待しますので、お待ちください。<br>
      3. アプリ紹介のダウンロードボタンからアプリをインストールしてください。
    </p>
    <h4>■テスト内容</h4>
    <p>
      1. アプリをインストールする<br>
      2. アプリを起動する<br>
      3. 15日間アプリを削除せず、毎日起動する<br> （アプリで遊ぶ必要も、フィードバックを書く必要もありません。）
    </p>
    <h4>■本募集の背景</h4>
    <p>
      GooglePlayでアプリの製品版をリリースするための条件として、20名による14日間のテストが必要となりました。<br>
      私個人ではGooglePlayアカウントを20個も所持しておりませんので、本募集をする運びとなりました。<br>
      <a href="https://support.google.com/googleplay/android-developer/answer/14151465?hl=ja">GooglePlayのアプリ公開要件</a>
    </p>
  </div>
  <div>
    <h2>スマートフォンアプリ一覧</h2>
    <fieldset>
      <legend class="app__title">
        かぴばらとあそぼ
      </legend>
      <div class="app__download">
        ☆テスター募集中（要項は先述）☆
      </div>
      <div>
        <div class="app__download">
          ダウンロード
        </div>
        <a
          href="https://play.google.com/store/apps/details?id=com.nariji.kapibaratoasobo"
          class="cta-button"
        >Android（クローズドテスト）</a>
        <a
          href="https://apps.apple.com/jp/app/%E3%81%8B%E3%81%B4%E3%81%B0%E3%82%89%E3%81%A8%E3%81%82%E3%81%9D%E3%81%BC/id6482973940"
          class="cta-button"
        >iOS</a>
      </div>
      <p class="app__content">
        すくすく育つかぴばらを観察しましょう。<br>
        かぴばらは時間が経つとおなかがすいたり、体が汚れてしまいます。<br>
        適度にごはんをあげたり、おふろに入れてあげてあげましょう。<br>
        仲良くなると、たからものをくれるかも？！<br>
      </p>
      <div class="app__scene">
        <div>
          <h3>☆タイトル画面☆</h3>
          <img
            src="https://d1ch691ms300nb.cloudfront.net/%E3%81%8B%E3%81%B4%E3%81%B0%E3%82%89%E3%81%A8%E3%81%82%E3%81%9D%E3%81%BC-TitleScene.png"
            alt="None"
          >
        </div>
        <div>
          <h3>☆ゲーム画面☆</h3>
          <img
            src="https://d1ch691ms300nb.cloudfront.net/%E3%81%8B%E3%81%B4%E3%81%B0%E3%82%89%E3%81%A8%E3%81%82%E3%81%9D%E3%81%BC-Game%E7%94%BB%E9%9D%A2.png"
            alt="None"
          >
        </div>
        <div>
          <h3>☆たからもの☆</h3>
          <img
            src="https://d1ch691ms300nb.cloudfront.net/%E5%AE%9D%E7%89%A9GetScene.png"
            alt="None"
          >
        </div>
      </div>
    </fieldset>
  </div>
</template>

<script>
// 標準モジュール
import { useRouter } from 'vue-router';

// 外部モジュール

// 独自モジュール

export default {
  name: 'GameTop',
  setup() {
    const router = useRouter()

    const openPage = (target) => {
      router.push(`/${target}`)
    }
    
    return {
      openPage,
    };
  },
}
</script>
