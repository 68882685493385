<template>
    <div>
      <h2>値段比較</h2>

      <div class="gap-btn">
        <v-btn @click="add_row(1)">追加</v-btn>
        <v-btn @click="del_row(1)">削除</v-btn>
        <v-btn @click="clear_form">クリア</v-btn>
      </div>

      <!-- チャット内容が動的に生成される部分 -->
      <v-table theme="dark" style="margin: 12px 0px;">
        <thead>
          <tr>
            <th class="text-left input-column">
                値段<br>（税込み）
            </th>
            <th class="text-left input-column">
                枚数<br>(1セット当たり)
            </th>
            <th class="text-left input-column">
                セット数
            </th>
            <th class="text-left input-column">
              1単位の値段<br>(g/ml/枚...)
            </th>
          </tr>
        </thead>
        <tbody>
            <tr
                v-for="(item, index) in items" :key="index"
            >
            <td>
                <v-text-field v-model="item.price" type="number" class="input-column"></v-text-field>
            </td>
            <td>
                <v-text-field v-model="item.piece_count" type="number" class="input-column"></v-text-field>
            </td>
            <td>
                <v-text-field v-model="item.pack_count" type="number" class="input-column"></v-text-field>
            </td>
            <td>
                {{ compare_price(item)}}
            </td>
          </tr>
        </tbody>
      </v-table>

      <div class="gap-btn">
        <v-btn @click="add_row(1)">追加</v-btn>
        <v-btn @click="del_row(1)">削除</v-btn>
        <v-btn @click="clear_form">クリア</v-btn>
      </div>
    </div>
</template>

<script>
import { ref, onMounted } from "vue";

  export default {
    name: 'KaimonoList',
    setup() {
      const total_row = ref(5)
      const price_per_piece = ref(0)
      const compare_price = (item) => {
          const price_per_piece = item.price / (item.piece_count * item.pack_count)
          return price_per_piece
      }

      /////////////////
      // フォームの追加
      /////////////////
      const items = ref([])
      const item_dict = {
          price: null,
          piece_count: null,
          pack_count: 1,
          price_per_piece: 0,
      }
      // const update_row = () => {
      //   if (items.value.length == total_row.value) {
      //     return 
      //   }
      //   else if (items.value.length < total_row.value) {
      //     const target_row_cnt = total_row.value - items.value.length
      //     add_row(target_row_cnt)
      //   } else {
      //     const target_row_cnt = items.value.length - total_row.value
      //     del_row(target_row_cnt)
      //   }
      // }
      const add_row = (row_cnt) => {
        for (let i = 0; i < row_cnt; i++) {
              items.value.push({ ...item_dict })
          }
      }
      const del_row = (row_cnt) => {
        if (items.value.length < 2) {
          return 
        }
        for (let i = 0; i < row_cnt; i++) {
              items.value.pop()
          }
      }
      const clear_form = () => {
        total_row.value = 5
        items.value = []
        for (let i = 0; i < total_row.value; i++) {
            items.value.push({ ...item_dict })
        }
      }

      onMounted (() => {
        clear_form()
      })


      return {
        items,
        total_row,
        price_per_piece,
        compare_price,
        add_row,
        del_row,
        clear_form
      }
    }
  }
</script>

<style scoped>
.input-column {
  min-width: 75px  
}
.gap-btn {
  gap: 12px;
  display: inline-flex;
}
</style>
  