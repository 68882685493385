<template>
    <div>
      <h2>値段比較</h2>

      <v-text-field v-model="total_row" type="number" label="列数" variant="solo-inverted" @input="update_row"></v-text-field>

      <!-- チャット内容が動的に生成される部分 -->
      <v-table theme="dark" style="margin-top: 20px;">
        <thead>
          <tr>
            <th class="text-left input-column">
                値段（税込み）
            </th>
            <th class="text-left input-column">
                1パック当たりの枚数
            </th>
            <th class="text-left input-column">
                パック数
            </th>
            <th class="text-left input-column">
              一枚当たりの値段
            </th>
          </tr>
        </thead>
        <tbody>
            <tr
                v-for="(item, index) in items" :key="index"
            >
            <td>
                <v-text-field v-model="item.price" type="number" class="input-column"></v-text-field>
            </td>
            <td>
                <v-text-field v-model="item.piece_count" type="number" class="input-column"></v-text-field>
            </td>
            <td>
                <v-text-field v-model="item.pack_count" type="number" class="input-column"></v-text-field>
            </td>
            <td>
                {{ compare_price(item)}}
            </td>
          </tr>
        </tbody>
      </v-table>
    </div>
</template>

<script>
import { ref, onMounted } from "vue";

  export default {
    name: 'KaimonoList',
    setup() {
      const total_row = ref(3)
        const price_per_piece = ref(0)
        const compare_price = (item) => {
            const price_per_piece = item.price / (item.piece_count * item.pack_count)
            return price_per_piece
        }

        const items = ref([])
        const item_dict = {
            price: 298,
            piece_count: 150,
            pack_count: 5,
            price_per_piece: 0,
        }
        const update_row = () => {
            items.value = []
            for (let i = 0; i < total_row.value; i++) {
                items.value.push({ ...item_dict })
            }
        }

        onMounted (() => {
            update_row()
      })


      return {
        items,
        total_row,
        price_per_piece,
        compare_price,
        update_row
      }
    }
  }
</script>

<style scoped>
.input-column {
    min-width: 75px  
}
</style>
  