<template>
    <div>
      <h2>買い物メモ - TOP</h2>
      <div>
        買い物メモ
      </div>
      <!-- <KaimonoList/> -->
      <KaimonoCalc/>
    </div>
  </template>
  
  <script>
  // import KaimonoList from '@/components/kaimono/KaimonoList.vue'
  import KaimonoCalc from '@/components/kaimono/KaimonoCalc.vue'
  
  export default {
    name: 'KaimonoTop',
    components: {
      // KaimonoList,
      KaimonoCalc,
    },
  }
  </script>
  