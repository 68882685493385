<template>
  <footer>
    <div class="contact">
      <div><span class="contact__column">代表者:</span> 成田丈二</div>
      <div><span class="contact__column">連絡先:</span> nariji.app@gmail.com</div>
      <div><span class="contact__column">X(Twitter):</span> @nariji_app</div>
    </div>
    <div class="rights">
      <a
        class="link"
        @click="openPage('PrivacyPolicy')"
      >プライバシーポリシー</a><br>
      &copy; 2024 なりじのかくれが. All rights reserved.
    </div>
  </footer>
</template>
  
  <script>
  // 標準モジュール
  import { useRouter } from 'vue-router';
  
  // 外部モジュール
  
  // 独自モジュール

  export default {
    name: 'FooterMenu',
    setup() {
    const router = useRouter()

    const openPage = (target) => {
      router.push(`/${target}`)
    }
     
    return {
      openPage,
    }
  }
}
</script>
  