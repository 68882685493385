<template>
  <GameTop/>
</template>

<script>
// 標準モジュール
import { useRouter } from 'vue-router';

// 外部モジュール

// 独自モジュール
import GameTop from '@/contents/GameTop.vue'

export default {
  name: 'TOP',
  components: {
    GameTop,
  },
  setup() {
    const router = useRouter()

    const openPage = (target) => {
      router.push(`/${target}`)
    }
    
    return {
      openPage,
    };
  },
}
</script>
