<template>
    <div>
      <h2>買い物メモ - TOP</h2>

      <div class="gap-btn">
        <v-btn @click="open_list">メモ</v-btn>
        <v-btn @click="open_calc">計算機</v-btn>
      </div>

      <br>

      <KaimonoList v-if="is_open_list" />
      <KaimonoCalc v-if="is_open_calc"/>
    </div>
  </template>
  
  <script>
  import { ref, onMounted } from "vue";
  import KaimonoList from '@/components/kaimono/KaimonoList.vue'
  import KaimonoCalc from '@/components/kaimono/KaimonoCalc.vue'
  
  export default {
    name: 'KaimonoTop',
    components: {
      KaimonoList,
      KaimonoCalc,
    },
    setup() {
      const is_open_list = ref(false)      
      const is_open_calc = ref(true)   
      
      const open_list = () => {
        is_open_list.value = true
        is_open_calc.value = false
      }
      const open_calc = () => {
        is_open_list.value = false
        is_open_calc.value = true
      }

      // ###############
      // ライフサイクル #
      // ###############      
      onMounted (() => {
        open_list()
      })

      return {
        is_open_list,
        is_open_calc,
        open_list,
        open_calc
      }
    }
  }
  </script>
  