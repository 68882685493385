<template>
    <div>
      <h2>買い物メモ</h2>
      <div>
      <div>
        <v-text-field v-model="name" label="商品名" variant="solo-inverted"></v-text-field>
      </div>
      <div>
        <v-text-field v-model="amount" type="number" label="数量" variant="solo-inverted"></v-text-field>
      </div>
      <div>
        <v-textarea v-model="comment" label="コメント" variant="solo-inverted"></v-textarea>
      </div>
      </div>

      <!-- メッセージを送る部分 -->
      <v-btn name="button" id="jsi-button" @click="post_items">送信</v-btn>

      <!-- チャット内容が動的に生成される部分 -->
      <v-table theme="dark" style="margin-top: 20px;">
        <thead>
          <tr>
            <th class="text-left">
              品物
            </th>
            <th class="text-left">
              数
            </th>
            <th class="text-left">
              コメント
            </th>
            <th class="text-left">
              削除
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
          v-for="cart_ite in cart_items" :key="cart_ite.name"
          >
            <td>{{ cart_ite.name }}</td>
            <td>{{ cart_ite.amount }}</td>
            <td>{{ cart_ite.comment }}</td>
            <td @click="del_items(cart_ite.name)">削除ボタン</td>
          </tr>
        </tbody>
      </v-table>
    </div>
</template>

<script>
import { ref, onMounted } from "vue";
// 全importにcompatを入れる。databaseのimportも必要: FireBase導入-パートD
import firebase from "firebase/compat/app";
import "firebase/compat/database";

  export default {
    name: 'KaimonoList',
    setup() {
      // ################
      // ソート用モジュール
      // ################
      const sortModule = require('lodash')

      // ################
      // フォーム
      // ################
      const name = ref("")
      const amount = ref(1)
      const comment = ref("")

      const init_form = ()=> {
        name.value = ""
        amount.value = 1
        comment.value = ""
      }

      // ################
      // DBへのデータ格納
      // ################
      const post_items = () => {
        if (name.value === ""){
          return
        }
        if (is_contain_cart_item(name.value)) {
          return
        }
        firebase.database().ref("cart_items")
        .push({
          name: name.value ? name.value : "",
          amount: amount.value ? name.value : 1,
          comment: comment.value ? name.value : "",
        })
        init_form()
        get_cart_items()
      }

      const del_items = (name) => {
        firebase.database().ref("cart_items")
          .once("value")
          .then((snapshot) => {
            let itemToDelete = null;

            // データをループして該当するnameを探す
            snapshot.forEach((childSnapshot) => {
              const key = childSnapshot.key; // アイテムのキー
              const data = childSnapshot.val(); // アイテムのデータ
              
              if (data.name === name) { // 該当するnameを発見
                itemToDelete = key;
              }
            });

            if (itemToDelete) {
              // 該当するデータを削除
              return firebase.database().ref(`cart_items/${itemToDelete}`).remove();
            } else {
              console.log("No item found with the given name.");
              return Promise.reject("Item not found");
            }
          })
          .then(() => {
            console.log("Item successfully deleted");
            get_cart_items(); // リストを再取得する関数を呼び出し
          })
          .catch((error) => {
            console.error("Error deleting item: ", error);
          });
      }

      // ##################
      // DBからのデータ取得
      // ##################
      const cart_items = ref([])

      const get_cart_items = () => {
        cart_items.value = [];
        firebase.database().ref("cart_items")
          .on("value", (data)=> {
            const rootList = data.val();
            if(rootList === null) { return }
            Object.keys(rootList).forEach((val) => {
              const rowData = rootList[val]
                cart_items.value.push({name: rowData.name, amount: Number(rowData.amount), comment: rowData.comment})
            })
            // 外部モジュールでソート
            cart_items.value = sortModule.sortBy(cart_items.value, 'point').reverse()
        })
      }

      // #############
      // Utils
      // #########
      const is_contain_cart_item = (item_name) => {
        console.log(item_name)
        // cart_items
      }

      // ###############
      // ライフサイクル #
      // ###############      
      onMounted (() => {
        get_cart_items()
      })

      return {
        // DBへのデータ格納
        post_items,
        init_form,
        name,
        amount,
        comment,
        // DBデータ操作
        get_cart_items,
        cart_items,
        del_items,
        //
        is_contain_cart_item
      }
    }
  }
</script>
  