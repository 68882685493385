import { createRouter, createWebHashHistory } from 'vue-router'
// import App from '@/App.vue'
import Top from '@/contents/Top.vue'

// 各ページ
import PrivacyPolicy from '@/contents/PrivacyPolicy.vue'
import GameTop from '@/contents/GameTop.vue'
import ProfileTop from '@/contents/ProfileTop.vue'
import MurmurTop from '@/contents/MurmurTop.vue'
import FireBaseTop from '@/contents/FireBaseTop.vue'
import AwsTop from '@/contents/AwsTop.vue'
import KaimonoTop from '@/contents/KaimonoTop.vue'

const routes = [
  {
    path: '/',
    name: 'Top',
    component: Top
  },
  {
    path: '/PrivacyPolicy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy
  },
  {
    path: '/GameTop',
    name: 'GameTop',
    component: GameTop
  },
  {
    path: '/ProfileTop',
    name: 'ProfileTop',
    component: ProfileTop
  },
  {
    path: '/MurmurTop',
    name: 'MurmurTop',
    component: MurmurTop
  },
  {
    path: '/FireBaseTop',
    name: 'FireBaseTop',
    component: FireBaseTop
  },
  {
    path: '/AwsTop',
    name: 'AwsTop',
    component: AwsTop
  },
  {
    path: '/KaimonoTop',
    name: 'KaimonoTop',
    component: KaimonoTop
  }, 
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router