<template>
  <div>
    <!-- <h2>FireBase - TOP</h2>
    <div>
      FireBaseを使って色々テストできるようにする(したい)
    </div> -->
    <!--
    // FireBase導入-パートA
    1. npm install -g firebase-tools
    2. npm install firebase
    3. firebase login
    4. firebase init
    5. firebase deploy
    -->
    <!-- <RealtimeDatabase/> -->
  </div>
</template>

<script>
// import RealtimeDatabase from '@/components/firebase/RealtimeDatabase.vue'

export default {
  name: 'FireBaseTop',
  components: {
    // RealtimeDatabase,
  },
}
</script>
