<template>
  <div>
    <h2>AWS - TOP</h2>
    <div>
      Lambdaを使って色々テストできるようにする(したい)
    </div>
    <LambdaService/>
  </div>
</template>

<script>
import LambdaService from '@/components/aws/LambdaService.vue'

export default {
  name: 'AwsTop',
  components: {
    LambdaService,
  },
}
</script>
