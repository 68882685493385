<template>
  <div>
    <h2>プライバシーポリシー(制定日: 2024/03/23)</h2>
    <fieldset>
      <legend class="app__title">
        はじめに
      </legend>
      <p class="app__content">
        このページは、なりじのかくれが(以下「私」と言います。)が提供するスマートフォンアプリ(以下「本アプリ」と言います。)における利用者情報の取扱いについて、以下のとおりアプリケーション・プライバシーポリシー（以下「本ポリシー」といいます。）を定め、公表しております。<br>
        本アプリをインストールする前に本ポリシーをご確認いただき、内容をご理解の上で本アプリをご利用下さい。<br>
        なお、本ポリシーにおいて、「利用者情報」とは、お客様の識別に係る情報、通信サービス上の行動履歴、その他お客様のスマートフォンにおいてお客様と結びついた形で生成、利用又は蓄積されている情報であって、本ポリシーに基づき当社が取得するものを意味するものとします。
      </p>
    </fieldset>
    <fieldset>
      <legend class="app__title">
        本アプリが利用する利用者情報
      </legend>
      <p class="app__content">
        本アプリが利用する利用者情報は、下記の３つです。<br>
        ・アプリの広告表示のためにお客様の情報の一部を送信する可能性があります。<br>
        ・アプリではアクセス解析ツールを用いてアプリの利用状況を収集しております。<br>
        ・問い合わせの際に利用者情報の一部を送信します。<br>
        <br>
        1. アプリの広告表示本アプリは広告表示のためにお客様の情報の一部を送信する可能性があります。<br>
        広告表示は、下記２社のサービスを利用しており、本アプリは２社に利用者情報の提供を行います。<br>
        それぞれのサービスが利用している利用者情報と目的については各社のサービスのプライバシーポリシーをご確認ください。<br>
        <br>
        <a href="https://www.google.com/policies/privacy/partners/">Admob プライバシーポリシー</a><br>

        <br>
        2. アプリのアクセス解析<br>
        本アプリではアクセス解析ツールを用いてアプリの利用状況を収集しております。<br>
        アクセス解析はGoogle Analyticsを利用しております。<br>
        本アプリは画面遷移を行った際に、画面IDと利用者情報をGoogle Analytics社に送付します。<br>
        <br>
        <a href="https://www.google.com/intl/en/policies/">Google Analytics プライバシーポリシー</a><br>
                
        <br>
        3. 問い合わせ<br>
        本アプリでは問い合わせの際に利用者情報の一部を送信します。<br>
        問い合わせはメールで行い、メール本文に下記情報が埋め込まれます。<br>
        また、メールで送信されるため、メールアドレスも送信されます。<br>
        名前、メールアドレス、端末名、OSバージョン、その他端末情報(画面サイズ、ピクセル密度、利用可能ヒープメモリ量)、アドオン購入状況
      </p>
    </fieldset>
    <fieldset>
      <legend class="app__title">
        利用目的
      </legend>
      <p class="app__content">
        利用者情報はそれぞれ下記目的に利用されます。<br>
        <br>
        ・第三者の広告の配信・表示のため<br>
        ・当社のサービスに関連して、個人を識別できない形式に加工した統計データを作成するため<br>
        ・本アプリに関するご案内、お問い合せ等への対応のため<br>
      </p>
    </fieldset>
  </div>
</template>

<script>
export default {
    name: 'PrivacyPolicy',
}
</script>
